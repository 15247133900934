import React from "react";
// import { Container, Typography } from "@mui/material";
import SliderComponent from "./Common/SliderComponent";

const SliderSelect = ({ data, setData }) => {
  const bank_limit = 5000;
  return (
    <div>
      <SliderComponent
        onChange={(e, value) => {
          setData({
            ...data,
            homeValue: value.toFixed(0),
            downPayment: (0.2 * value).toFixed(0),
            loanAmount: (0.8 * value).toFixed(0),
          });
        }}
        defaultValue={data.homeValue}
        min={1000}
        max={bank_limit}
        steps={100}
        unit="UF"
        amount={data.homeValue}
        label="Valor de la propiedad"
        value={data.homeValue}
      />

      <SliderComponent
        onChange={(e, value) =>
          setData({
            ...data,
            downPayment: value.toFixed(2),
            loanAmount: data.homeValue * ( 1 - value / 100).toFixed(2),
          })
        }
        defaultValue={data.downPayment}
        min={10}
        max={50}
        steps={5}
        unit="%"
        amount={data.downPayment}
        label="Pie"
        value={data.downPayment}
      />

      <SliderComponent
        onChange={(e, value) =>
          setData({
            ...data,
            loanAmount: value.toFixed(0),
            downPayment: (data.homeValue - value).toFixed(0),
          })
        }
        defaultValue={data.loanAmount}
        min={0}
        max={data.homeValue}
        steps={100}
        unit="UF"
        amount={data.loanAmount}
        label="Préstamo"
        value={data.loanAmount}
      />

      <SliderComponent
        onChange={(e, value) =>
          setData({
            ...data,
            interestRate: value.toFixed(2),
          })
        }
        defaultValue={data.interestRate}
        min={4}
        max={7}
        steps={0.1}
        unit="%"
        amount={data.interestRate}
        label="Tasa de interés"
        value={data.interestRate}
      />
    </div>
  );
};

export default SliderSelect;
